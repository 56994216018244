<template>
  <div v-if="clientData" class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-12">
          <i class="icon-people" style="margin-right: 0.5rem"></i>
          {{
            clientData.prospect && clientData.prospect.isProspect
              ? "Prospect"
              : "Client"
          }}: {{ clientData.email }} - N°
          {{ clientData.matricule }}
        </div>
      </div>
      <div class="row" v-if="!clientData.deletedAt">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-sm btn-danger float-left mt-1"
            v-if="
              clientData.prospect &&
              clientData.prospect.isProspect &&
              hasPermission($store.state.user, 'PROSPECTS_DELETE')
            "
            @click="removeClient()"
          >
            Supprimer
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger float-left mt-1"
            v-else-if="hasPermission($store.state.user, 'CLIENTS_DELETE')"
            @click="removeClient()"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>

    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Email de contact</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.contactEmail"
            size="mini"
            @change="editClient('contactEmail', $event)"
            :class="{ 'has-error': !clientData.contactEmail }"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.contactEmail }}</span>
        </dd>
        <dt class="col-sm-4">Prénom</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.firstName"
            size="mini"
            @change="editClient('firstName', $event)"
            :class="{ 'has-error': !clientData.firstName }"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.firstName }}</span>
        </dd>
        <dt class="col-sm-4">Nom</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.lastName"
            size="mini"
            @change="editClient('lastName', $event)"
            :class="{ 'has-error': !clientData.lastName }"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.lastName }}</span>
        </dd>
        <dt class="col-sm-4">Société</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.company"
            size="mini"
            @change="editClient('company', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.company }}</span>
        </dd>
        <dt class="col-sm-4">Client B2B</dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="clientData.isB2B"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('isB2B', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt v-if="clientData.isB2B" class="col-sm-4">Établissement marchés</dt>
        <dd v-if="clientData.isB2B" class="col-sm-8">
          <el-switch
            v-model="clientData.marketEstablishment"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('marketEstablishment', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt class="col-sm-4">Genre</dt>
        <dd class="col-sm-8">
          <el-select
            v-model="clientData.gender"
            size="mini"
            @change="editClient('gender', $event)"
            :class="{ 'has-error': !clientData.gender }"
            :disabled="!checkPermission"
          >
            <el-option value="Homme" />
            <el-option value="Femme" />
          </el-select>
        </dd>
        <dt class="col-sm-4">Préférence</dt>
        <dd class="col-sm-8">
          <el-select
            v-model="clientData.preferedCoachGender"
            size="mini"
            @change="editClient('preferedCoachGender', $event)"
            :disabled="!checkPermission"
          >
            <el-option value="Homme" />
            <el-option value="Femme" />
            <el-option value="Peu importe" />
          </el-select>
        </dd>
        <dt v-if="clientData.strictCoachGender" class="col-sm-4">
          Préférence stricte
        </dt>
        <dd v-if="clientData.strictCoachGender" class="col-sm-8">
          <el-input
            v-model="clientData.strictCoachGender"
            size="mini"
            @change="editClient('strictCoachGender', $event)"
            v-if="checkPermission"
          />
        </dd>
        <dt class="col-sm-4">Téléphone</dt>
        <dd class="col-sm-8">
          <PhoneNumberInput
            v-if="checkPermission"
            :phone-number="clientData.phone"
            :phone-code="clientData.phoneCode"
            size="mini"
            copy
            required
            @changeNumber="editClient('phone', $event)"
            @changeCode="editClient('phoneCode', $event)"
          />
          <span v-else
            ><span v-if="clientData.phoneCode"
              >({{ clientData.phoneCode }}) </span
            >{{ clientData.phone }}</span
          >
        </dd>
        <dt class="col-sm-4">Date de naissance</dt>
        <dd class="col-sm-8">
          <el-date-picker
            v-model="clientData.birthDate"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            type="date"
            size="small"
            :class="{ 'has-error': !clientData.birthDate }"
            @change="editClient('birthDate', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ formatDate(client.birthDate) }}</span>
        </dd>
        <dt class="col-sm-4">Lieux des séances</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.preferedPlace"
            size="mini"
            @change="editClient('preferedPlace', $event)"
            :class="{ 'has-error': !clientData.preferedPlace }"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.preferedPlace }}</span>
        </dd>
        <dt class="col-sm-4">Personnes / séances</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.preferedSession"
            size="mini"
            @change="editClient('preferedSession', $event)"
            :class="{ 'has-error': !clientData.preferedSession }"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.preferedSession }}</span>
        </dd>
        <dt class="col-sm-4">Pack souhaité</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.interestedPack"
            size="mini"
            @change="editClient('interestedPack', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.interestedPack }}</span>
        </dd>
        <dt class="col-sm-4">Découvert par</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="clientData.discoveredBy"
            size="mini"
            @change="editClient('discoveredBy', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.discoveredBy }}</span>
        </dd>
        <dt class="col-sm-4">Concepts</dt>
        <dd class="col-sm-8">
          <multiselect
            v-model="clientData.concepts"
            :options="concepts"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            @input="editClient('concepts', $event)"
            label="name"
            track-by="name"
            placeholder="Choisir"
            class="custom-multiselect"
            :disabled="!checkPermission"
          >
          </multiselect>
        </dd>
        <dt class="col-sm-4">Groupes</dt>
        <dd class="col-sm-8">
          <multiselect
            v-model="clientData.groups"
            :options="groups"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            @select="addClientGroup($event)"
            @remove="removeClientGroup($event)"
            label="name"
            track-by="name"
            placeholder="Choisir"
            class="custom-multiselect"
            :disabled="!checkPermission"
          >
          </multiselect>
        </dd>
        <dt class="col-sm-4">
          Notification Email

          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Le client reçoit ou non les emails concernant la validation des séances etc.',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="clientData.emailNotification"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('emailNotification', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt class="col-sm-4">
          Offre SMS/Email
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Important en cas d\'export pour la campagne de newsletter.',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="clientData.newsletter"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('newsletter', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt class="col-sm-4">Avis Google</dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="clientData.googleNotice"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('googleNotice', $event)"
            :disabled="!checkPermission"
          />
          <span v-if="clientData.googleNoticeSince" class="text-muted ml-1">
            Avis posté le {{ formatDate(clientData.googleNoticeSince) }}
          </span>
        </dd>
        <dt class="col-sm-4">Avis Trustpilot</dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="clientData.trustpilotNotice"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('trustpilotNotice', $event)"
            :disabled="!checkPermission"
          />

          <span v-if="clientData.trustpilotNoticeSince" class="text-muted ml-1">
            Avis posté le {{ formatDate(clientData.trustpilotNoticeSince) }}
          </span>
        </dd>
        <dt class="col-sm-4">
          Demande d'avis

          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Permet de relancer le client par mail pour qu\'il pense à laisser un avis',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <button
            @click="sendRatingEmail('trustpilot')"
            class="btn btn-success mr-05"
            style="background-color: #00b67a; border-color: #00b67a"
            :disabled="trustpilotReviewDisabled"
            v-tooltip="{
              content:
                'Envoie un mail qui récapitule les dernières commandes du client et le prévient qu\'il recevra un autre mail de Trustpilot pour donner son avis',
              placement: 'top',
            }"
          >
            <i class="fa fa-star mr-05"></i> Avis Trustpilot
          </button>
          <button
            @click="sendRatingEmail('google')"
            class="btn btn-primary"
            style="background-color: #4285f4; border-color: #4285f4"
          >
            <i class="fa fa-google mr-05"></i> Avis Google
          </button>
        </dd>
        <dt class="col-sm-4">
          Accès Live Admin

          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Permet au client de se connecter sans payer, ni remplir d\'informations bancaires',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="clientData.admin"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('admin', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt class="col-sm-4" v-if="checkPermission">
          Mot de passe
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content: 'Pour l\'accès à l\'application client',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8" v-if="checkPermission">
          <button
            type="button"
            class="btn btn-primary"
            @click="sendLostPasswordEmail"
          >
            Email "mot de passe oublié"
          </button>
        </dd>
        <dt class="col-sm-4">
          Coach prospecté (Scrapping)

          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Le coach de se client à été prospecté spécifiquement pour lui, car aucun autre n\'était présent dans la zone.',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            :value="clientData.prospectedCoach"
            active-text="oui"
            inactive-text="non"
            size="mini"
            @change="toggleProspectedCoach"
            :disabled="!checkPermission"
          />
          <span class="ml-1" v-if="clientData.prospectedCoach">
            Prospecté le {{ formatDate(clientData.prospectedAt) }} par
            {{ clientData.prospectedBy }}</span
          >
        </dd>
        <dt class="col-sm-4">Compte créé</dt>
        <dd class="col-sm-8">{{ formatDate(clientData.createdAt) }}</dd>
        <dt class="col-sm-4" v-if="ordersPrice">Total des commandes</dt>
        <dd class="col-sm-8" v-if="ordersPrice">
          {{ formatPrice(ordersPrice) }}
        </dd>
        <dt class="col-sm-4">Coachs</dt>
        <dd class="col-sm-8">
          <div
            class="col-sm-12 p-0"
            v-for="(coach, index) in clientData.coachs"
            :key="index"
          >
            <a :href="coachHref(coach.id)" target="_blank">
              {{ coach.coach.name }}
            </a>
            <span v-if="coach.coach.phone">
              {{ coach.coach.phone }}
            </span>
          </div>
        </dd>
        <dt class="col-sm-4" v-if="payers.length > 0">Payeur</dt>
        <dd class="col-sm-8" v-if="payers.length > 0">
          <p class="m-0" v-for="(payer, index) in payers" :key="index">
            {{ payer }}
          </p>
        </dd>
        <dt class="col-sm-4" v-if="clientData.stripeKeys">
          Carte(s) de crédit
        </dt>
        <dd class="col-sm-8" v-if="clientData.stripeKeys">
          <span v-if="clientData.stripeKeys.length === 0">-</span>
          <a
            v-else
            :key="`stripe-keys-${index}`"
            v-for="(item, index) of clientData.stripeKeys"
            :href="stripeCustomer(item.customerId)"
            target="_blank"
            class="btn btn-sm btn-link"
          >
            <i class="icon-credit-card"></i>&nbsp;
            <span v-if="item.conceptName">{{ item.conceptName }}</span>
          </a>
        </dd>
        <dt class="col-sm-4">Fréquence</dt>
        <dd class="col-sm-8" v-if="orders.length > 0">
          {{ averageClientFrequency(orders) || "-" }}
        </dd>
        <dd class="col-sm-8" v-else>
          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </dd>
      </dl>

      <div class="mb-1">
        <button
          type="button"
          class="btn btn-secondary"
          v-if="dateLastCompletedSession"
        >
          Séance validée
          {{ dateLastCompletedSession.format("DD.MM.YYYY") }}
        </button>
        <button
          type="button"
          class="btn btn-info"
          v-if="dateNextScheduledSession"
        >
          Programmé le {{ dateNextScheduledSession.format("DD.MM.YYYY") }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-if="dateLastCompletedSession && !dateNextScheduledSession"
        >
          Inactif depuis {{ daysInactive }} jours
        </button>
      </div>

      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="showBilling = true"
        >
          <i class="icon-doc"></i> Informations de facturation
        </button>
      </div>
    </div>

    <modal
      title="Modifier les informations de facturation"
      v-model="showBilling"
      cancelText="Fermer"
      okText="Enregistrer"
      effect="fade/zoom"
      @ok="handleEditBilling()"
    >
      <div class="card card-inverse card-warning text-center">
        <div class="card-block">
          <blockquote class="card-blockquote">
            Les informations seront utilisées pour la création de la facture.
          </blockquote>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-6">
          <label>Nom</label>
          <div class="input-group">
            <input
              v-model="clientData.billing.lastName"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ clientData.billing.lastName }}</span>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Prénom</label>
          <div class="input-group">
            <input
              v-model="clientData.billing.firstName"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ clientData.billing.firstName }}</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Société</label>
        <div class="input-group">
          <textarea
            v-model="clientData.billing.company"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.billing.company }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>Service</label>
        <div class="input-group">
          <input
            v-model="clientData.billing.service"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.billing.service }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>Adresse</label>
        <div class="input-group">
          <input
            v-model="clientData.billing.address"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ clientData.billing.address }}</span>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Code postal</label>
          <div class="input-group">
            <input
              v-model="clientData.billing.zipcode"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ clientData.billing.zipcode }}</span>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Ville</label>
          <div class="input-group">
            <input
              v-model="clientData.billing.city"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ clientData.billing.city }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Email</label>
          <div class="input-group">
            <input
              v-model="clientData.billing.email"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ clientData.billing.email }}</span>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <label>Téléphone</label>
          <PhoneNumberInput
            v-if="checkPermission"
            :phone-number="clientData.billing.phone"
            :phone-code="clientData.billing.phoneCode"
            copy
            @changeNumber="changeBillingPhone"
            @changeCode="changeBillingPhoneCode"
          />
          <div v-else>
            <span v-if="clientData.billing.phoneCode"
              >({{ clientData.billing.phoneCode }}) </span
            >{{ clientData.billing.phone }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Description (pré-rempli la création de commande)</label>
          <div class="input-group">
            <textarea
              v-model="clientData.billing.description"
              type="text"
              class="form-control"
              v-if="checkPermission"
            ></textarea>
            <span v-else>{{ clientData.billing.description }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <div class="commercial-toggle">
            <label
              class="switch switch-sm switch-text switch-info mb-0"
              v-if="checkPermission"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="clientData.billing.showCommercial"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="txt-sap">
              {{
                clientData.billing.showCommercial
                  ? "Afficher"
                  : "Ne pas afficher"
              }}
              les informations du commercial dans la facture
            </span>
          </div>
        </div>
      </div>
    </modal>

    <ModalProspectedCoach
      :visible="showModalProspectedCoach"
      :close="closeProspectedCoach"
      :updateClient="addedProspectedCoach"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import Multiselect from "vue-multiselect";
import FrequencyMixin from "../mixin/FrequencyMixin";
import Modal from "vue-strap/src/Modal";
import PhoneNumberInput from "./PhoneNumberInput";
import ModalProspectedCoach from "./coachManager/scrapping/prospected-coach/ModalProspectedCoach.vue";

export default {
  components: {
    Multiselect,
    Modal,
    PhoneNumberInput,
    ModalProspectedCoach,
  },

  mixins: [FrequencyMixin],

  props: {
    client: {
      type: Object,
      required: true,
    },

    orders: {
      type: Array,
      default: () => [],
    },

    sessions: {
      type: Array,
      default: () => [],
    },

    concepts: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      clientData: undefined,
      showBilling: false,
      showModalProspectedCoach: false,
    };
  },

  computed: {
    payers() {
      const payers = [];

      if (this.client.payments) {
        for (const payment of this.client.payments) {
          if (payment.payer && !payers.includes(payment.payer)) {
            payers.push(payment.payer);
          }
        }
      }

      return payers;
    },

    ordersPrice() {
      let price = 0;

      this.orders.forEach((order) => {
        if (order.status === "refunded") {
          price -= order.price;
        } else {
          price += order.price;
        }
      });

      return price;
    },

    dateLastCompletedSession() {
      let dateCurrent = moment();
      let date = null;

      let sessions = this.sessions.filter(
        (s) => !!s.scheduledAt && s.completed
      );

      sessions.forEach((s) => {
        let scheduledAt = moment(s.scheduledAt);

        if (
          scheduledAt <= dateCurrent &&
          (date == null || scheduledAt > date)
        ) {
          date = scheduledAt;
        }
      });

      return date;
    },

    daysInactive() {
      if (!this.dateLastCompletedSession) return null;

      return moment().diff(this.dateLastCompletedSession, "days");
    },

    dateNextScheduledSession() {
      let dateCurrent = moment();
      let date = null;

      let sessions = this.sessions.filter((s) => !!s.scheduledAt);

      sessions.forEach((s) => {
        let scheduledAt = moment(s.scheduledAt);

        if (
          scheduledAt >= dateCurrent &&
          (date == null || scheduledAt < date)
        ) {
          date = scheduledAt;
        }
      });

      return date;
    },

    checkPermission() {
      return (
        this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") &&
        !this.client.deletedAt
      );
    },

    trustpilotReviewDisabled() {
      return !this.orders.some((o) => !!o.invoice || o.free);
    },
  },

  watch: {
    client: {
      handler() {
        this.clientData = this.client;
      },
      deep: true,
    },
  },

  created() {
    this.clientData = this.client;
  },

  methods: {
    toggleProspectedCoach() {
      if (!this.clientData.prospectedCoach) {
        this.showModalProspectedCoach = true;
      } else {
        this.addedProspectedCoach({
          prospectedBy: null,
          prospectedAt: null,
          prospectedCoach: false,
        });
      }
    },

    closeProspectedCoach() {
      this.showModalProspectedCoach = false;
    },

    async addedProspectedCoach(updateData) {
      try {
        const { data } = await this.$api.patch(
          `/clients/${this.client.id}/coach-manager`,
          {
            prospectedBy: updateData.prospectedBy,
            prospectedAt: updateData.prospectedAt,
            prospectedCoach: updateData.prospectedCoach,
          }
        );

        this.clientData.prospectedCoach = data.prospectedCoach;
        this.clientData.prospectedBy = data.prospectedBy;
        this.clientData.prospectedAt = data.prospectedAt;
        this.$successToast(data.message);
      } catch (e) {
        this.$errorToast("La mise à jour du client a échoué");
      }
    },

    editClient(name, value) {
      this.$emit("edit", { name, value });
    },

    removeClient() {
      this.$confirm({
        message:
          "Attention, cette action est irreversible ! Le client sera anonymisé et vous ne retrouverez plus ses informations dans les commandes existantes.",
        onConfirm: async () => {
          try {
            await this.$api.delete("/clients", {
              data: {
                email: this.client.email,
              },
            });

            this.$router.push({
              name: "clients",
            });
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    sendLostPasswordEmail() {
      this.$confirm({
        message: `Attention, cela va envoyer un email à l'adresse suivante : ${this.clientData.usedEmail}`,
        onConfirm: async () => {
          try {
            await this.$api.post("/client-app/auth/reset-password", {
              email: this.clientData.contactEmail,
            });

            this.$successToast("Email envoyé");
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    async addClientGroup(event) {
      this.$api.post(`/groups/${event.id}/add-client`, {
        clientEmail: this.client.email,
      });
    },

    async removeClientGroup(event) {
      this.$api.post(`/groups/${event.id}/remove-client`, {
        clientEmail: this.client.email,
      });
    },

    coachHref(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    stripeCustomer(customerId) {
      return "https://dashboard.stripe.com/customers/" + customerId;
    },

    async handleEditBilling() {
      await this.$api.put("/clients", {
        email: this.clientData.email,
        billing: this.clientData.billing,
      });

      this.showBilling = false;
    },

    changeBillingPhone(phone) {
      this.clientData.billing = {
        ...this.clientData.billing,
        phone,
      };
    },

    changeBillingPhoneCode(phoneCode) {
      this.clientData.billing = {
        ...this.clientData.billing,
        phoneCode,
      };
    },

    sendRatingEmail(platform) {
      let message = "";

      switch (platform) {
        case "google":
          message =
            "Voulez-vous envoyer un email au client pour demander un avis Google ?";
          break;
        case "trustpilot":
          message =
            "Voulez-vous envoyer un email au client pour demander un avis Trustpilot ?";
          break;
        default:
          message =
            "Voulez-vous envoyer un email au client pour demander un avis ?";
          break;
      }

      this.$confirm({
        message,
        onConfirm: async () => {
          try {
            await this.$api.post("/clients/rating/mail", {
              platform,
              email: this.client.email,
            });

            this.$successToast("Email envoyé");
          } catch (e) {
            this.$errorToast("Impossible d'envoyer l'email");
          }
        },
      });
    },
  },
};
</script>
